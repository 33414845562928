










































import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins, Vue } from "vue-property-decorator";
import {
  NavBar,
  Row,
  Col,
  Icon,
  Image,
  Progress,
  Popup,
  Button,
  List,
  Toast,
} from "vant";
import PrintRecords from "./PrintRecords.vue";
import ExchangeRecord from "./ExchangeRecord.vue";
import { printListDtos } from "@/shims.decorator";
import CollectPrintList from "@/components/CollctPrint/CollectPrintList.vue";

// import { parse } from "qs";
@Component({
  components: {
    [NavBar.name]: NavBar,
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Image.name]: Image,
    [Progress.name]: Progress,
    [Popup.name]: Popup,
    [Button.name]: Button,
    [List.name]: List,
    PrintRecords,
    ExchangeRecord,
    CollectPrintList,
  },
})
export default class CollectionPrinting extends Mixins(Mixin) {
  public showPrintRecords = false; // 印花记录
  public showExchangeRecords = false; // 兑换记录
  public activityId = "";
  public printList: Array<printListDtos> = [];
  public list = {
    loading: false,
    finished: false,
  };
  public page = {
    current: 1,
    size: 10,
  };

  mounted(): void {
    this.getActivity(true);
  }

  onClickLeft(): void {
    this.$router.go(-1);
  }
  onClickRight(): void {
    this.showExchangeRecords = true;
    this.$nextTick(() => {
      let exchangeRecord = this.$refs["exchange-records"] as ExchangeRecord;
      if (exchangeRecord) {
        exchangeRecord.getRecords();
      }
    });
  }
  close(): void {
    this.showExchangeRecords = false;
  }
  goActivity(id: string | undefined, state: string | undefined): void {
    this.$router.push(
      `/print-activity?id=${id as string}&state=${state as string}`
    );
  }
  exchangeGood(activityId: string | undefined): void {
    this.$router.push(`/print-exchange?activityId=${String(activityId)}`);
  }
  goPrintRecord(id: string | undefined): void {
    this.showPrintRecords = true;
    this.activityId = id as string;
  }
  getActivity(first: boolean): void {
    Toast.loading({
      duration: 0, // 持续展示 toast
      forbidClick: true,
    });
    if (first) {
      this.page.current = 1;
    } else {
      this.page.current++;
    }
    this.$api.memberApi.printing.myPrintingList(
      {
        pageNum: this.page.current,
        pageSize: this.page.size,
      },
      ({ data }) => {
        Toast.clear();
        if (this.page.current === 1) {
          this.printList = data.records! as Array<printListDtos>;
        } else {
          this.printList = this.printList.concat(
            data.records! as Array<printListDtos>
          );
        }
        this.list.loading = false;
        this.list.finished = data.current! >= data.pages!;
      }
    );
  }
  // 将字符串日期转成时间戳
  dataFormat(dataString: string | undefined): number {
    let dataTmp = (dataString as string).replace(/-/g, "/");
    let timestamp = Date.parse(dataTmp);
    let newTime = new Date().getTime();
    return newTime - timestamp;
  }
  percentageValue(finishNumber: number, taskNumber: number): number {
    return parseInt(String((finishNumber / taskNumber) * 100));
  }
}
