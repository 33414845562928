import { render, staticRenderFns } from "./CollectionPrinting.vue?vue&type=template&id=3f732468&scoped=true&"
import script from "./CollectionPrinting.vue?vue&type=script&lang=ts&"
export * from "./CollectionPrinting.vue?vue&type=script&lang=ts&"
import style0 from "./CollectionPrinting.vue?vue&type=style&index=0&id=3f732468&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f732468",
  null
  
)

export default component.exports